<templete>
<svg
    :aria-labelledby="iconName"
    :height="height"
    :width="width"
    role="presentation"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
>
  <title id="iconName"> {{ iconName }}} icon </title>
  <g :fill="iconColor">
    <slot/>
  </g>

</svg>
</templete>

<script>
export default {
   name: 'AmcIcon',
   props: {
      iconName: {
         type: String,
         default: 'box',
      },
      width: {
         type: [Number, String],
         default: 18,
      },
      height: {
         type: [Number, String],
         default: 18,
      },
      iconColor: {
         type: String,
         default: 'currentColor',
      },
   },
};
</script>

<style lang="scss" scoped>
.amc-icon {
   line-height: 1;

   i {
      line-height: 1;
   }

   &.amc-icon--stacked {
      width: 35px;
      height: 35px;
      display: flex;
      background: #2f71f2;
      align-items: center;
      border-radius: 0.5rem;
      justify-content: center;
   }
}
</style>
